import apiCall from '@/lib/axiosApi'
import { setIndexBy } from '@/lib/objects'
import ls from '@/lib/localStorageUtils'
import Vue from 'vue'

const dictName = 'GNVLPDict'

const groupByField = 'ath_group'

const state = {
  // Info
  dictIndicationsInfo: ls.loadDictVersion(dictName, {}),
  // СПРАВОЧНИК
  dictIndications: null,
}

const getters = {
  dictIndicationsVersion: (_, getters) =>
    getters.dictIndicationsInfo.version ?? '',
  dictIndicationsDescription: (_, getters) =>
    getters.dictIndicationsInfo.description ?? '...',
  dictIndicationsInfo: state => state.dictIndicationsInfo || {},
  dictIndications: state => state.dictIndications || [],

  availableIndications: (state, getters) => (mnnId, lecformId) => {
    const medicationIndex = getters.dictIndications.findIndex(
      ({ id }) => id === mnnId
    )
    if (medicationIndex < 0) return
    const lecformIndex = getters.dictIndications[medicationIndex].lfs.findIndex(
      ({ id }) => id === lecformId
    )
    if (lecformIndex < 0) return
    const { loading, indications } =
      getters.dictIndications[medicationIndex].lfs[lecformIndex]
    return { medicationIndex, lecformIndex, loading, indications }
  },

  // Заглушка - получаем справочник атх-мнн из справочника показаний
  dictAtc5Mnn: (_, getters) =>
    getters.dictIndications
      .map(({ id, ath, mnn }) => ({ id, ath, mnn }))
      .sort((a, b) => String(a?.mnn).localeCompare(b?.mnn)),
}

const mutations = {
  /**
   * ПОЛУЧАЕМ (И ПЕРЕУСТАНАВЛИВАЕМ) ЗНАЧЕНИЯ ВЕРСИИ И СПРАВОЧНИКА
   */
  GET_INDICATIONS_LOCAL(state) {
    try {
      state.dictIndicationsInfo = ls.loadDictVersion(dictName, {})
      state.dictIndications = setIndexBy(
        ls.loadDictData(dictName),
        groupByField
      )
    } catch {
      state.dictIndicationsInfo = {}
      state.dictIndications = null
    }
  },
  /**
   * УСТАНАВЛИВАЕМ ЗНАЧЕНИЕ ВЕРСИИ И СПРАВОЧНИКА
   */
  SET_INDICATIONS_DICT(state, { version, description, data }) {
    state.dictIndicationsInfo = { version, description }
    state.dictIndications = setIndexBy(data, groupByField)
    ls.saveDict(dictName, { version, description }, data)
  },
  SET_LEKFORM(state, { medicationIndex, lecformIndex, lecform }) {
    const lfs = state.dictIndications[medicationIndex].lfs
    Vue.set(lfs, lecformIndex, { ...lfs[lecformIndex], ...lecform })
  },

  // Добавляет запись всего препарата в GVNLPDict
  add_med_to_gvnlp_dict(state, medicine) {
    state.dictIndications.push(medicine)
  },
  // добавляет лекформу в препарат
  add_lecform_to_gvnlp_dict(state, lecform) {
    const index =
      state.dictIndications?.findIndex(
        med => med.id === lecform.dict_gnvlp_medicine_id
      ) ?? -1
    if (index === -1) return
    let lfs = state.dictIndications[index].lfs ?? []
    lfs.push(lecform)
    Vue.set(state.dictIndications[index], 'lfs', lfs)
  },

  // Изменяет запись из GVNLPDict: или весь препарат или лекформу
  edit_gvnlp_dict(state, { med_id, lf_id, newMed, newLf }) {
    const index =
      state.dictIndications?.findIndex(med => med.id === med_id) ?? -1
    if (index === -1) return
    const med = { ...state.dictIndications[index], ...(newMed || {}) }
    if (lf_id) {
      const lf_index = med.lfs.findIndex(lf => lf.id === lf_id) ?? -1
      if (lf_index === -1) return
      Vue.set(med.lfs, lf_index, { ...med.lfs[lf_index], ...newLf })
    }
    Vue.set(state.dictIndications, index, med)
  },

  // Удаляет запись из GVNLPDict: или весь препарат или лекформу
  delete_from_gvnlp_dict(state, { med_id, lf_id }) {
    const index =
      state.dictIndications?.findIndex(med => med.id === med_id) ?? -1
    if (lf_id) {
      let lfs = state.dictIndications[index].lfs ?? []
      const lfIndex = lfs.findIndex(lf => lf.id === lf_id) ?? -1
      if (lfIndex === -1) return
      lfs.splice(lfIndex, 1)
      Vue.set(state.dictIndications[index], 'lfs', lfs)
      return
    }
    if (med_id) {
      if (index === -1) return
      state.dictIndications.splice(index, 1) // удаляем запись препарата
    }
  },
}

const actions = {
  /**
   * ПОЛУЧАЕМ СПРАВОЧНИК ПОКАЗАНИЙ, СРАВНИВАЕМ ВЕРСИИ
   */
  GET_INDICATIONS_DICT: async ({ commit, getters, state }) => {
    const currentVersion = getters.dictIndicationsVersion
    try {
      const { version, description, data } = await apiCall(
        `dict/gnvlp?version=${currentVersion}`
      )
      if (data) {
        commit('SET_INDICATIONS_DICT', { version, description, data })
      } else {
        if (!state.dictIndications) commit('GET_INDICATIONS_LOCAL')
      }
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник Показаний',
        text: 'Ошибка загрузки справочника Показаний',
        error,
      })
      throw error
    }
  },

  // Заглушка - получаем справочник атх-мнн из справочника показаний
  async GET_ATC5MNN_DICT({ dispatch, getters }) {
    await dispatch('GET_INDICATIONS_DICT')
    return getters.dictAtc5Mnn
  },

  // загружаем список доступных показаний к применению по МНН, АТХ, ЛекФорме
  async GET_INDICATIONS(
    { commit, getters },
    { medicationId, lecformId, force = false }
  ) {
    let { medicationIndex, lecformIndex, loading, indications } =
      getters.availableIndications(medicationId, lecformId) ?? {}
    if ((lecformIndex === undefined || loading || indications) && !force) {
      return
    }

    // устанавливаем флаг загрузки показаний
    commit('SET_LEKFORM', {
      medicationIndex,
      lecformIndex,
      lecform: {
        loading: true,
        error: false,
      },
    })

    try {
      indications = await apiCall(
        `dict/gnvlp/indications/get-by-lf-id/${lecformId}`
      )
      // если всё ок, то в лекформу подгружаем ещё показания, снимаем флаг загрузки
      commit('SET_LEKFORM', {
        medicationIndex,
        lecformIndex,
        lecform: {
          loading: false,
          indications,
        },
      })
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник Показаний',
        text: 'Ошибка загрузки показаний справочника Показаний',
        error,
      })
      commit('SET_LEKFORM', {
        medicationIndex,
        lecformIndex,
        lecform: {
          loading: false,
          error: true,
        },
      })
      throw error
    }
  },

  // добавить препарат в справочник
  async ADD_MED_TO_GVNLP_DICT({ commit }, medicine) {
    try {
      const response = await apiCall('dict/gnvlp/medicines/', medicine, 'POST')
      commit('add_med_to_gvnlp_dict', response)
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник GNVLP',
        text: 'Ошибка добавления препарата в справочник показаний',
        error,
      })
      throw error
    }
  },
  async ADD_LECFORM_TO_GNVLP_DICT({ commit }, lecform) {
    try {
      const response = await apiCall('dict/gnvlp/lf/', lecform, 'POST')
      commit('add_lecform_to_gvnlp_dict', response)
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник GNVLP',
        text: 'Ошибка добавления лекформы в справочник показаний',
        error,
      })
      throw error
    }
  },
  async ADD_INDICATION_TO_GNVLP_DICT(
    { commit, dispatch },
    { indication, medication }
  ) {
    try {
      const response = await apiCall(
        'dict/gnvlp/indications/',
        indication,
        'POST'
      )
      await dispatch('GET_INDICATIONS', {
        medicationId: medication.id,
        lecformId: response.dict_gnvlp_lf_id,
        force: true,
      })
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник GNVLP',
        text: 'Ошибка добавления показания к препарату в справочник показаний',
        error,
      })
      throw error
    }
  },

  // удалить из справочника - или препарат, или лекформу, или показание
  async DELETE_MEDICINE_FROM_GNVLP(
    { commit, dispatch },
    { med_id, lf_id, ind_id }
  ) {
    const target = ind_id ? 'indications' : lf_id ? 'lf' : med_id ? 'med' : null
    const dict = {
      med: {
        url: `dict/gnvlp/medicines/${med_id}`,
        text: 'Ошибка удаления лекарственного препарата из справочника показаний',
      },
      lf: {
        url: `dict/gnvlp/lf/${lf_id}`,
        text: 'Ошибка удаления лекформы из справочника показаний',
      },
      indications: {
        url: `dict/gnvlp/indications/${ind_id}`,
        text: 'Ошибка удаления показания из справочника показаний',
      },
    }
    try {
      await apiCall(dict[target].url, undefined, 'DELETE')
      if (!ind_id) {
        commit('delete_from_gvnlp_dict', { med_id, lf_id })
      } else {
        await dispatch('GET_INDICATIONS', {
          medicationId: med_id,
          lecformId: lf_id,
          force: true,
        })
      }
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник GNVLP',
        text: dict[target].text,
        error,
      })
      throw error
    }
  },

  // изменить запись в справочнике: или препарат, или лекформу, или показание
  async EDIT_GNVLP_DICT(
    { commit, dispatch },
    { medication, lecform, indication }
  ) {
    const target = indication
      ? 'indications'
      : lecform
      ? 'lf'
      : medication
      ? 'med'
      : null
    const dict = {
      med: {
        url: medication ? `dict/gnvlp/medicines/${medication.id}` : undefined,
        data: medication,
        text: 'Ошибка сохранения препарата в справочник показаний',
      },
      lf: {
        url: lecform ? `dict/gnvlp/lf/${lecform.id}` : undefined,
        data: lecform,
        text: 'Ошибка сохранения лекформы в справочник показаний',
      },
      indications: {
        url: indication ? `dict/gnvlp/indications/${indication.id}` : undefined,
        data: indication,
        text: 'Ошибка сохранения показания препарата в справочнике показаний',
      },
    }
    try {
      const response = await apiCall(
        dict[target].url,
        dict[target].data,
        'PATCH'
      )
      if (target === 'med') {
        commit('edit_gvnlp_dict', { med_id: response.id, newMed: response })
      } else if (target === 'lf') {
        commit('edit_gvnlp_dict', {
          med_id: medication.id,
          newMed: medication,
          lf_id: response.id,
          newLf: response,
        })
      } else {
        await dispatch('GET_INDICATIONS', {
          medicationId: medication.id,
          lecformId: lecform.id,
          force: true,
        })
      }
    } catch (error) {
      commit('SET_ERROR', {
        head: 'Справочник показаний',
        text: dict[target].text,
        error,
      })
      throw error
    }
  },

  async CHECK_ATH_EXISTS(_, ath) {
    const url = `dict/ath/code/${ath}`
    return await apiCall(url)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
