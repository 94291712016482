// типы экспертиз
export const EX_EXPERTISE = 'expertise' // Экспертиза
export const EX_FOREIGN = 'foreign' // Зарубежный опыт
export const EX_SUMMARY = 'summary-view' //Представление о ЛП
export const EX_PRICING = 'ref-pricing' //Референтное ценообразование
export const EX_CONCLUSION = 'conclusion' //Сводное заключение
export const EX_COST = 'cost-calc' //Расчёт затрат
export const EX_MODIFICATION = 'modification' //Экспертиза доработки

// export const EX_ASSESSMENT = 'assessment' //Оценка экспертной организаций

export const EXPERTISE_ICON = exType => {
  return (
    {
      [EX_EXPERTISE]: 'mdi-alpha-e-circle-outline',
      [EX_FOREIGN]: 'mdi-web',
      [EX_MODIFICATION]: 'mdi-wrench',
      [EX_SUMMARY]: 'mdi-presentation',
      [EX_PRICING]: 'mdi-earth',
      [EX_CONCLUSION]: 'mdi-medical-bag',
      [EX_COST]: 'mdi-calculator',
      // [EX_ASSESSMENT]: 'mdi-account-multiple',
    }[exType] || null
  )
}

//Список всех проводимых экспертиз
export const EX_LIST = [
  EX_EXPERTISE,
  EX_FOREIGN,
  EX_CONCLUSION,
  EX_SUMMARY,
  EX_PRICING,
  EX_COST,
  EX_MODIFICATION,
]
export const EX_LIST_EXPERTISE = [
  EX_EXPERTISE,
  EX_CONCLUSION,
  EX_SUMMARY,
  EX_PRICING,
  EX_COST,
  EX_MODIFICATION,
] //Список can_execute_expertise
export const EX_LIST_FOREIGN = [EX_FOREIGN] //Список can_evaluate_agencies
// подтипы экспертиз
export const SUB_EX_ABOUT = 'about' // Информация о препарате
export const SUB_EX_ANALOG = 'analog' // Аналоги
export const SUB_EX_KEI = 'kei' // КЕИ
export const SUB_EX_KEI_MOD = 'kei-mod' //Доработка КЭИ
export const SUB_EX_AVB = 'avb' // АВБ
export const SUB_EX_AVB_MOD = 'avb-mod' //Доработка АВБ
export const SUB_EX_OTHER = 'other' // прочие данные
export const SUB_EX_AGE = 'agencies' // Агенства
export const SUB_EX_SUMMARY = 'summary' //Представление о ЛП

export const SUB_EX_CONCLUSION = 'conclusion' //Сводное заключение
export const SUB_EX_PRICING = 'ref' //Референтное ценообразование
export const SUB_EX_COST = 'cost' //Расчет затрат

export const SUB_EX_ASSESSMENT = 'assessment' //Оценка экспертной организаций

// дерево экспертиз
export const EX = {
  [EX_EXPERTISE]: [
    SUB_EX_ABOUT,
    SUB_EX_ANALOG,
    SUB_EX_KEI,
    SUB_EX_AVB,
    SUB_EX_OTHER,
    SUB_EX_CONCLUSION,
    SUB_EX_SUMMARY,
    SUB_EX_PRICING,
    SUB_EX_COST,
    SUB_EX_ASSESSMENT,
  ],
  [EX_FOREIGN]: [SUB_EX_AGE],
  [EX_MODIFICATION]: [SUB_EX_KEI_MOD, SUB_EX_AVB_MOD],
}

// экспертизы для отчёта КО
export const SUB_EXPERTISE_FOR_REPORT = [
  SUB_EX_ABOUT,
  SUB_EX_ANALOG,
  SUB_EX_KEI,
  SUB_EX_AVB,
  SUB_EX_KEI_MOD,
  SUB_EX_AVB_MOD,
  SUB_EX_OTHER,
  SUB_EX_AGE,
  SUB_EX_ASSESSMENT,
]

export const SUB_EXPERTISE_FOR_CONCLUSION_REPORT = [SUB_EX_CONCLUSION]

export const SUB_EXPERTISE_FOR_SUMMARY_REPORT = [
  SUB_EX_SUMMARY,
  SUB_EX_PRICING,
  SUB_EX_COST,
]

export const SUB_EXPERTISE_FOR_KO_REPORT = [
  SUB_EX_ABOUT,
  SUB_EX_COST,
  SUB_EX_ANALOG,
  SUB_EX_OTHER,
  SUB_EX_AGE,
  SUB_EX_ASSESSMENT,
]

export const SUB_EXPERTISE_FOR_METHOD_REPORT = [
  SUB_EX_ABOUT,
  SUB_EX_ANALOG,
  SUB_EX_KEI_MOD,
  SUB_EX_AVB_MOD,
  SUB_EX_KEI,
  SUB_EX_AVB,
]

// пути до файлов констант ( с миграциями )
const PATH_EX_CONST = {
  [SUB_EX_ABOUT]: () =>
    import(
      /* webpackChunkName: "main-expertise" */ '@/components/expAgency/const'
    ),
  [SUB_EX_OTHER]: () =>
    import(
      /* webpackChunkName: "main-expertise" */ '@/components/expOther/const'
    ),
  [SUB_EX_KEI]: () =>
    import(
      /* webpackChunkName: "main-expertise" */ '@/components/expAvbKei/const'
    ),
  [SUB_EX_KEI_MOD]: () =>
    import(
      /* webpackChunkName: "main-expertise" */ '@/components/expModAvbKei/const'
    ),
  [SUB_EX_AVB]: () =>
    import(
      /* webpackChunkName: "main-expertise" */ '@/components/expAvbKei/const'
    ),
  [SUB_EX_AVB_MOD]: () =>
    import(
      /* webpackChunkName: "main-expertise" */ '@/components/expModAvbKei/const'
    ),
  [SUB_EX_AGE]: () =>
    import(
      /* webpackChunkName: "main-agency" */ '@/components/expAgency/const'
    ),
  [SUB_EX_CONCLUSION]: () =>
    import(
      /* webpackChunkName: "main-agency" */ '@/components/expConclusion/const'
    ),
  [SUB_EX_SUMMARY]: () =>
    import(
      /* webpackChunkName: "main-agency" */ '@/components/expSummary/const'
    ),
  [SUB_EX_PRICING]: () =>
    import(
      /* webpackChunkName: "main-agency" */ '@/components/expPricing/const'
    ),
  [SUB_EX_COST]: () =>
    import(/* webpackChunkName: "main-agency" */ '@/components/expCost/const'),
  [SUB_EX_ASSESSMENT]: () =>
    import(
      /* webpackChunkName: "main-agency" */ '@/components/expAssessment/const'
    ),
}

async function tryImport(ex_type_sub) {
  const comp = PATH_EX_CONST[ex_type_sub]
  if (comp) await comp()
}

// версии и методы конвертации

/** Мигратор версий данных экспертиз  */
export const SUB_VERSION_MIGRATION = {
  ['test_migration']: {
    [1]: test_1_to_2,
    [2]: test_2_to_3,
    v: 3,
  },
  [SUB_EX_ABOUT]: { v: 1 },
  [SUB_EX_ANALOG]: { v: 1 },
  [SUB_EX_KEI]: { v: 1 },
  [SUB_EX_KEI_MOD]: { v: 1 },
  [SUB_EX_AVB]: { v: 1 },
  [SUB_EX_AVB_MOD]: { v: 1 },
  [SUB_EX_OTHER]: { v: 1 },
  [SUB_EX_AGE]: { v: 1 },
  [SUB_EX_CONCLUSION]: { v: 1 },
  [SUB_EX_SUMMARY]: { v: 1 },
  [SUB_EX_PRICING]: { v: 1 },
  [SUB_EX_COST]: { v: 1 },
  [SUB_EX_ASSESSMENT]: { v: 1 },
}

// Пример миграции версии -(наверное лучше вынести слождные функции в сонстанты экспертизы)
function test_1_to_2(prev) {
  // пример добавиль новле поле vers = 2
  return { ...(prev || {}), vers: 2 }
}

function test_2_to_3(prev) {
  // пример убрали новое поле vers
  // eslint-disable-next-line no-unused-vars
  const { vers, ...newData } = prev
  return newData
}

/** Возвращает текущую версию данных сабэкспертизы */
export async function sub_exp_version(ex_type_sub) {
  await tryImport(ex_type_sub)
  return SUB_VERSION_MIGRATION[ex_type_sub]?.v ?? 1
}

/** вполняет миграцию по типу функции */
export async function sub_exp_migration(ex_type_sub, version, data) {
  const data_version = await sub_exp_version(ex_type_sub)
  version = version ?? 1
  // если нет данных то и мигрировать не нужно
  if (!data) return { data, data_version: version }

  while (data_version > version) {
    const func = SUB_VERSION_MIGRATION[ex_type_sub]?.[version]
    if (func) {
      data = func(data)
      console.log(
        'MIGRATION_EXPERTISE',
        ex_type_sub,
        version,
        'to',
        version + 1
      )
    }
    version++
  }
  return { data, data_version }
}
